export const BACKGROUNDGIFS = [
  'https://media.giphy.com/media/5RKNOaiojPbbMUsikF/source.gif?cid=ecf05e479pld0m0evo3bpu2tz6n9cj2m45yv92n8czf6yfsp&rid=source.gif&ct=g',
  'https://media.giphy.com/media/WFjcyy95rUXPXo1k43/source.gif?cid=ecf05e4774dohdv6obllir5spuffv1t25xszv8o69vk1z3my&rid=source.gif&ct=g',
  'https://media.giphy.com/media/l3q2vQ6rlZutXsYN2/source.gif?cid=ecf05e47vz5st49khj1l7tnj3cxofv4j3mc1o6ady2ovrry0&rid=source.gif&ct=g',
  'https://media.giphy.com/media/YqhIK6Gbor6CLeloBq/source.gif?cid=ecf05e47ksfr47u384gxd5d9j0ff706gvxnaw02ycflok1nh&rid=source.gif&ct=g',
  'https://media.giphy.com/media/hugQmENCWTyPM3yG5D/source.gif?cid=ecf05e47buaj0yolklpw8xrfdlp4vlj8y6l8haj8z177y79x&rid=source.gif&ct=g',
  'https://media.giphy.com/media/WmjDOxrhzaiYHb7MPg/source.gif?cid=ecf05e475chd4vdsm47vsnvb2gpljxles5ayqfvyie1qqrcr&rid=source.gif&ct=g',
  'https://media.giphy.com/media/Td4BPgayuhUqNtwIP9/source.gif?cid=ecf05e47biu3a2xqfptgcoc7wfmibg0jhso98ts3vemhwvw9&rid=source.gif&ct=g',
  'https://media.giphy.com/media/l1J9xRxf0x8zMnA4g/source.gif?cid=ecf05e47293t2tk3oju4vuuulb11wwom93wcfwajt3s0orug&rid=source.gif&ct=g',
  'https://media.giphy.com/media/kBwLmrLOxFvXhSVMGe/source.gif?cid=ecf05e47hgdblcz5p6wiubkfxhgi08bryqalxd6uoiqqvwy2&rid=source.gif&ct=g',
  'https://media.giphy.com/media/OK5LK5zLFfdm/giphy.gif?cid=ecf05e474s86enstnjhkvjl9btrfdhvg1az61lolzj7s232v&rid=giphy.gif&ct=g',
  'https://media.giphy.com/media/Wy3Q0fuNIJmYn62Lcv/source.gif?cid=ecf05e47ssoxleyb8x8iicup6kp0s9a5uvqrhesvdhbnbl9d&rid=source.gif&ct=g',
  'https://media.giphy.com/media/eJoZAwRN9OI5QjthIE/source.gif?cid=790b76111ded9860ebdc7ced5e45f4b63358c6614e716ce6&rid=source.gif&ct=g',
  'https://media.giphy.com/media/RjfXaD7oKscJaGO7c8/source.gif?cid=ecf05e477oe6tozjapmlsxyn6728ngnei725hfunn6pms7s9&rid=source.gif&ct=g',
  'https://media.giphy.com/media/9JxkPTP3alOykb8PmQ/source.gif?cid=ecf05e47zh5aa4mhdyyvjk45kr31jx4z9h2c2kwvxtl16jul&rid=source.gif&ct=g',
  'https://media.giphy.com/media/lFNmSjRDN9SiLJ3a9Z/source.gif?cid=ecf05e479a31u1tmbvkpx8gnhzw86afpl9trgylo8b6x7pwf&rid=source.gif&ct=g',
  'https://media.giphy.com/media/fpdCg5peSEE8tihDOs/source.gif?cid=790b7611bc576f4ecdae30e420e05dbfc8c4f27c63fb9248&rid=source.gif&ct=g',
  'https://media.giphy.com/media/jjMzVt3jaDfzoKr49R/source.gif?cid=790b761198deaa17248d3a475fecfc490023423ce58ef6c2&rid=source.gif&ct=g',
  'https://media.giphy.com/media/1gdr0AVrek4Q1fOs3Z/source.gif?cid=790b76112f51b4dd54bc320e48bc242424aa41b48726ebeb&rid=source.gif&ct=g',
  'https://media.giphy.com/media/auKRHcLuzy6ME/source.gif?cid=ecf05e472uqchn1yrm1tcxrmc44b7k9if1szedot7nwgava6&rid=source.gif&ct=g',
  'https://media.giphy.com/media/ODcgqrqIeNuewjiqow/source.gif?cid=ecf05e47lin6o6e5urhkrabzqfxvyvqn6ujzaeciuoi9rssw&rid=source.gif&ct=g'
];