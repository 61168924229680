export const TABS = [
  {
    tabName: 'HOME',
    link: 'top'
  },
  {
    tabName: 'PROJECTS',
    link: 'projects'
  },
  {
    tabName: 'EXPERIENCE',
    link: 'experience'
  },
  {
    tabName: 'ABOUT',
    link: 'about'
  },
  {
    tabName: 'CONTACT',
    link: 'contact'
  },
]